<template>
  <div class="container">
    <div class="uk-card uk-card-default uk-card-body">
      <!-- Filtre ve Rapor Alanı -->
      <div class="uk-margin">
        <div class="uk-grid-small" uk-grid>
          <div class="uk-width-expand@s">
            <div class="uk-inline uk-width-1-1">
              <label class="uk-form-label">  {{$t('exam.exam_choose')}}</label>
              <div class="uk-form-controls">
                <select v-model="selectedExam" class="uk-select" >
                  <option selected value="">{{$t('exam.exam_choose')}}</option>
                  <option v-for="(exam,index) in examList" :key="index" :value="exam">{{ exam.pre_exam_name }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="uk-width-auto@s uk-flex uk-flex-middle uk-flex-bottom">
            <button class="uk-button uk-button-primary" @click="getReport">
              <span uk-icon="icon: search"></span> {{ $t('general.search') }}
            </button>
          </div>
        </div>
      </div>

      <default-loading v-if="isLoading"/>

      <!--  Sınav Seçim Uyarısı - Hiç sınav seçilmediğinde ve henüz rapor istenmediğinde -->
      <div v-if="!isLoading && !hasRequestedReport" class="uk-alert uk-alert-warning uk-border-rounded alertbg">
        <i class="uil-comment-alt-exclamation"></i>
        <p>{{$t('general.see_exam_result')}}</p>
        
      </div>

      <!-- Sonuç Bulunamadı - Sınav seçilmiş, istek atılmış ama sonuç yoksa -->
      <div v-if="selectedExam && hasRequestedReport && !isLoading && userResults.length === 0"
           class="uk-alert uk-alert-warning uk-border-rounded alertbg">
           <i class="uil-comment-alt-exclamation"></i>
          <p>{{$t('general.no-exam-reports-found')}}</p>
      </div>

      <!-- Sınav Rapor Sonuçları -->
      <div v-if="userResults.length > 0" class="uk-margin-medium-top">
        <!-- Kullanıcı Bazlı Sonuçlar -->
        <div v-for="(user, index) in userResults" :key="index" class="uk-margin-small-bottom">
          <div class="uk-card uk-card-default uk-card-small">
            <div class="uk-card-header uk-padding-small">
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-auto profile-img">
                  <i class="uk-text-large uil-user-circle"></i>
                  <!-- <img :src="require('@/assets/images/avatars/none-avatar.jpg')" alt="Avatar" class="uk-border-circle" height="40" width="40"> -->
                </div>
                <div class="uk-width-expand profile-info">
                  <h3 class="uk-card-title uk-margin-remove-bottom">{{ user.user_name }}</h3>
                  <p class="uk-text-meta uk-margin-remove-top">#{{ user.user_id }}</p>
                </div>
                <div class="uk-width-auto uk-flex uk-flex-middle">
                  <div class="uk-flex uk-flex-middle uk-margin-right box">
                    <img src="/media/images/icon-pretest.png" />
                    <div>
                      <p>{{$t('general.pre_test_result')}}</p>
                      <span class="uk-text-large uk-text-bold">{{ user.pre_points }}</span>
                    </div>
                  </div>
                  <div class="uk-flex uk-flex-middle uk-margin-right box">
                    <img src="/media/images/icon-posttest.png" />
                    <div>
                      <p>{{$t('general.post_test_result')}}</p>
                      <span class="uk-text-large uk-text-bold">{{ user.post_points }}</span>
                    </div>
                  </div>
                  <div class="uk-flex uk-flex-middle">
                    <div class="uk-text-large uk-text-bold">
                      =
                    </div>
                    <div v-if="user.post_points>=user.pre_points" class="uk-margin-small-left">
                      <span class="uk-label uk-label-success uk-text-bold uk-border-rounded d-flex align-items-center result-label"><i class="uil-tachometer-fast"></i> {{ $t('report.success') }}</span>
                    </div>
                    <div v-else class="uk-margin-small-left">
                      <span class="uk-label uk-label-danger uk-text-bold">{{ $t('report.unsuccessful') }}</span>
                    </div>
                  </div>
                </div>
                <div class="uk-width-auto">
                  <a class="uk-icon-link" href="#" @click.prevent="toggleDetails(user.user_id,index)">
                    <span><i :class="user.showDetails ? 'uil-angle-up':'uil-angle-down'"/></span>
                    <span class="uk-margin-small-left">{{
                        user.showDetails ? $t('general.hide_questions')   : $t('general.show_quesions')
                      }}</span>
                  </a>
                </div>
              </div>
            </div>

            <!-- Soru Detayları -->
            <div v-if="user.showDetails" class="uk-card-body uk-padding-small" uk-animation="slide-top; duration: 300">
              <div v-for="(question, qIndex) in user.results" :key="qIndex"
                   class="uk-margin-small-bottom uk-card uk-card-default uk-card-body uk-padding-small">
                <div class="question-result-area uk-grid-small" uk-grid>
                  <!-- Question Column - Left Aligned -->
                  <div class="uk-width-3-5@m">
                    <h4 class="uk-margin-small-bottom">{{ $t('general.question') }} {{ qIndex + 1 }}</h4>
                    <p>{{ question.question_text }}</p>
                  </div>

                  <!-- Results Column -->
                  <div class="uk-width-2-5@m">
                    <div class="uk-grid-small uk-child-width-1-3@s" uk-grid>
                      <!-- PRE Result -->
                      <div class="uk-flex uk-flex-column align-items-center justify-content-center border-right-line">
                        <img width="40" src="/media/images/icon-pretest.png" />
                        <div
                            :class="['uk-text-bold uk-text-center', question.pre_is_correct.includes('true') ? 'uk-text-success' : 'uk-text-danger']">
                          {{ $t(question.pre_is_correct) }}
                        </div>
                      </div>

                      <!-- POST Result -->
                      <div class="uk-flex uk-flex-column align-items-center justify-content-center border-right-line">
                        <img width="40" src="/media/images/icon-posttest.png" />
                        <div
                            :class="['uk-text-bold uk-text-center', question.post_is_correct.includes('true') ? 'uk-text-success' : 'uk-text-danger']">
                          {{ $t(question.post_is_correct) }}
                        </div>
                      </div>

                      <!-- Final Result -->
                      <div class="uk-flex uk-flex-row result-label">                      
                        <p class="uk-text-bold d-flex align-items-center text-center" :class="question.result_status.includes('unsuccessful')?'uk-text-danger':'uk-text-success'">
                          <i :class="question.result_status.includes('unsuccessful')?'uil-times-circle':'uil-check-circle'"></i> {{ $t(question.result_status) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DefaultLoading from "@/view/components/loading/DefaultLoading.vue";
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";
import axios from "axios";
import store from "@/core/services";

const _MODULE_NAME = MODULE_NAME;

export default {
  components: {
    DefaultLoading
  },
  data() {
    return {
      selectedExam: "",
      examList: [],
      userResults: [],
      hasRequestedReport: false  // istek yapılırsa diye kontrol et

    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  computed: {
    isLoading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
  },
  mounted() {
    this.getExams()
  },
  methods: {
    getReport() {
      this.getUserFilteredReport()
      this.hasRequestedReport = true;  // Set this flag when report is requested
      this.isLoading = true;

      // API'dan veri alma simülasyonu
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    toggleDetails(userId, index) {
      // Doğrudan ilgili indeksteki kullanıcıya erişiyoruz
      const user = this.userResults[index];

      // Eğer showDetails özelliği yoksa, onu ekleyin
      if (user.showDetails === undefined) {
        this.$set(user, 'showDetails', true);
      } else {
        // Varsa tersine çevirin
        user.showDetails = !user.showDetails;
      }
    },
    async getUserFilteredReport() {
      if (!this.selectedExam || this.selectedExam === '') {
        // Sınav seçilmediğinde sonuçları temizleyelim
        this.userResults = [];
        return;
      }

      try {
        const {pre_exam_id, post_exam_id} = this.selectedExam;
        this.isLoading = true;
        const {data} = await axios.get(`/api/report/pre-post?pre_exam_id=${pre_exam_id}&post_exam_id=${post_exam_id}`);
        this.userResults = data || []; // Veri yoksa boş dizi ata
      } catch (error) {
        console.error("Rapor alınırken hata oluştu:", error);
        this.userResults = []; // Hata durumunda boş dizi ata
      } finally {
        this.isLoading = false;
      }
    },
    async getExams() {
      const exams = await store
          .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
            url: "api/pre-post-exams",
          })
      this.examList = exams?.data
    },
  }
};
</script>

<style scoped>
.uk-label-success {
  background-color: #32d296;
}

.uk-label-danger {
  background-color: #f0506e;
}

.uk-background-primary {
  background-color: #1e87f0;
}

.uk-background-secondary {
  background-color: #222;
}

.uk-text-success {
  color: #32d296 !important;
}

.uk-text-danger {
  color: #f0506e !important;
}


.question-result-area {
  display: flex;
  align-items: center;
}

@media (max-width: 959px) {
  .question-result-area {
    flex-direction: column;
  }
}

.uk-badge {
  display: block;
  margin-bottom: 8px !important;
}

.uk-flex-column {
  text-align: center;
}

/* Optional: Add some spacing for better readability */
.uk-card-body {
  padding: 20px;
}

.box{
  background-color:#f8f8fb;
  border-radius:15px;
  padding:.5rem;
}

.box img{
  width:36px;
  margin-right:.5rem;
}

.box p{
margin:0;
font-size:.75rem;
font-weight:600;
}

.box span{
  font-size:1.4rem;
  margin-top: -8px;
    display: block;
}

.result-label i{
  margin-right:.2rem;
  font-size:2rem;
}

.profile-img i{
  font-size:1.75rem;
}


.profile-info .uk-card-title {
  font-size:1rem;
}

.border-right-line{
  border-right:1px solid #ccc;
}

.alertbg{
  text-align:center;
}

.alertbg i{
  font-size:2rem;
}
</style>